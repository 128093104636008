.sobre__container {
    background-image: radial-gradient(#015832, #003b43);
    min-height: 100vh;
}

.sobre__align--container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    row-gap: 2rem;
}

.sobre__h2 {
    color: white;
    text-transform: uppercase;
}

.sobre__info--container {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.sobre__img--mobile {
    border-radius: 50%;
    filter: drop-shadow(0 0 .5rem black);
    height: 15rem;
    width: 15rem;
}

.sobre__text {
    line-height: 1.5rem;
    text-align: justify;
    width: 70%;
}

.sobre__img--desktop {
    display: none;
}

blockquote {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

blockquote p {
    width: 70%;
}

blockquote cite {
    width: 70%;
}

.sobre__container .inside__container {
    margin-top: 2rem;
}


@media screen and (min-width: 576px) {
    .sobre__text {
        max-width: 600px;
    }
}


@media screen and (min-width: 1024px) {
    .sobre__h2 {
        font-size: 2rem;
        margin-top: 2rem;
    }

    .sobre__info--container {
        column-gap: 10vh;
        flex-direction: row;
        justify-content: center;
    }

    .sobre__img--mobile {
        display: none;
    }

    .sobre__text {
        line-height: 2rem;
        width: 40%;
    }

    .sobre__img--container {
        align-items: center;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 40%;
    }

    .sobre__img--desktop {
        border-radius: 50%;
        filter: drop-shadow(0 0 .5rem black);
        display: block;
        height: 15rem;
        width: 15rem;
    }
}


@media screen and (min-width: 1440px) {
    .sobre__align--container{
        row-gap: 2.5rem;
    }

    .sobre__h2 {
        font-size: 2.5rem;
    }

    .sobre__info--container {
        font-size: 1.2rem;
    }

    .sobre__img--desktop {
        height: 18rem;
        width: 18rem;
    }

    .sobre__text {
        line-height: 2.5rem;
    }

}



@media screen and (min-width: 1720px) {
    .sobre__align--container{
        row-gap: 3.5rem;
    }

    .sobre__h2 {
        font-size: 3rem;
    }

    .sobre__info--container {
        font-size: 1.5rem;
    }

    .sobre__img--desktop {
        height: 22rem;
        width: 22rem;
    }

    .sobre__text {
        line-height: 3rem;
    }

    .sobre__img--container {
        row-gap: 2rem;
    }

    blockquote {
        line-height: 2rem;
    }

}