.links__container {
    background-image: radial-gradient(#015832 20%, #003b43);
    border-radius: .5rem;
    color: white;
    display: flex;
    filter: drop-shadow(0 0 .3rem black);
    flex-direction: column;
    left: .2%;
    position: fixed;
    padding: .8rem .5rem;
    row-gap: .8rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
}

.links__icon {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
}

.links__icon:hover {
    color: var(--color-green);
    transition: .8s;
}



@media screen and (min-width: 1440px) {
    .links__icon {
        font-size: 2rem;
    }
}



@media screen and (min-width: 1720px) {
    .links__icon {
        font-size: 2.5rem;
    }
}