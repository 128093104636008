.eventos {
    background-image: radial-gradient(#015832, #003b43);
    min-height: 100vh;
}

.eventos .navbar {
    position: relative;
}

.eventos .events__h2 {
    display: block;
    padding-top: 1rem;
}

.eventos .events__titulo {
    display: none;
}

.eventos .events__section--container {
    flex-direction: column;
    padding-bottom: 4rem;
    row-gap: 4rem;
}



@media screen and (min-width: 1024px) {
    .eventos .events__h2 {
        padding-top: 0;
        margin-top: 3rem;
    }

    .eventos .events__section--container {
        flex-direction: row;
        column-gap: 15vh;
    }

    .eventos .events__eventoAtual--container,
    .eventos .events__eventoPassado--container {
        border: none;
        flex-direction: column;
        padding-bottom: 0;
        width: auto;
    }

    .eventos .listasEvento__container {
        flex-direction: column;
        row-gap: .5rem;
    }

    .eventos .listasEvento__img {
        height: 10rem;
        width: 20rem;
    }

    .eventos .listasEvento__number {
        font-size: 2rem;
    }

    .eventos .listasEvento__date {
        column-gap: .5rem;
        flex-direction: row;
    }

    .eventos .listasEvento__text {
        display: none;
    }

    .eventos .button__container {
        position: static;
        transform: translate(0);
    }
}



@media screen and (min-width: 1440px) {
    .eventos .events__section--container {
        padding: 4rem;
    }

    .eventos .listasEvento__img {
        height: 15rem;
        width: 27rem;
    }

    .eventos .events__section--container {
        padding-top: 1rem;
    }

    .eventos .listasEvento__number {
        font-size: 2.7rem;
        margin-top: .5rem;
    }

    .eventos .listasEvento__date {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .eventos .button__container {
        font-size: 1.3rem;
        width: 15rem;
    }
}



@media screen and (min-width: 1720px) {
    .eventos .events__section--container {
        padding: 2rem 6rem 6rem;
    }

    .eventos .listasEvento__img {
        border-radius: 1rem;
        height: 17rem;
        width: 32rem;
    }

    .eventos .events__section--container {
        padding-top: 2rem;
    }

    .eventos .listasEvento__number {
        font-size: 3.5rem;
        margin-top: 1rem;
    }

    .eventos .listasEvento__date {
        font-size: 1.5rem;
        margin-bottom: 1.2rem;
    }

    .eventos .button__container {
        border-radius: 1rem;
        font-size: 1.5rem;
        width: 17rem;
    }
}