.inside__itens--container {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    row-gap: 1rem;
}

.inside__h2 {
    text-transform: uppercase;
}

.inside__textos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: .5rem;
    text-align: center;
}

.inside__input {
    border: none;
    border-bottom-left-radius: .5rem;
    border-top-left-radius: .5rem;
    height: 2rem;
    outline: none;
    padding: 0 .5rem;
}

.inside__input::placeholder {
    text-align: center;
}

.inside__textos p {
    width: 20rem;
}

.inside__textos--input {
    align-items: center;
    display: flex;
    justify-content: center;
}

.inside__button {
    align-items: center;
    background-color: black;
    border: 1px solid black;
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem;
    color: white;
    display: flex;
    height: 2rem;
    padding: 0 .8rem;
    text-decoration: none;
}

.inside__rodape {
    margin: 2rem 0 1rem 0;
}


@media screen and (min-width: 1024px) {
    .inside__container {
        padding-top: 6rem;
    }

    .inside__h2 {
        font-size: 2rem;
    }

    .inside__textos {
        align-items: center;
        column-gap: 3rem;
        flex-direction: row;
    }

    .inside__textos p {
        width: auto;
    }
}


@media screen and (min-width: 1440px) {
    .inside__h2 {
        font-size: 2.5rem;
    }

    .inside__textos p {
        font-size: 1.5rem;
    }

    .inside__textos input {
        font-size: 1rem;
        height: 3rem;
        padding: 1rem;
    }

    .inside__textos input::placeholder {
        font-size: 1rem;
    }

    .inside__button {
        height: 3rem;
        font-size: 1.2rem;
        padding: .8rem 1.5rem 1rem 1.5rem;
    }

    .inside__rodape {
        font-size: 1.2rem;
    }
}



@media screen and (min-width: 1720px) {
    .inside__container {
        padding-top: 10rem;
    }

    .inside__itens--container {
        row-gap: 2rem;
    }

    .inside__h2 {
        font-size: 3rem;
    }

    .inside__textos p {
        font-size: 1.7rem;
    }

    .inside__textos input {
        font-size: 1.2rem;
        height: 3.5rem;
        padding: 1.5rem;
        width: 18rem;
    }

    .inside__textos input::placeholder {
        font-size: 1.2rem;
    }

    .inside__button {
        height: 3.5rem;
        font-size: 1.5rem;
        padding: 1rem 1.7rem 1.2rem 1.7rem;
    }

    .inside__rodape {
        font-size: 1.4rem;
        margin: 2.5rem 0;
    }
}