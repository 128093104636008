.galeria__container {
    background-image: radial-gradient(#015832, #003b43);
    min-height: 100vh;
}

.galeria__container .navbar {
    position: relative;
}

.galeria__container .events__h2 {
    display: block;
    padding-top: 1rem;
    text-transform: uppercase;
}

.galeria__container .events__titulo {
    display: none;
}

.galeria__container .events__section--container {
    padding-bottom: 4rem;
}

.galeria__container .listasEvento__number,
.galeria__container .listasEvento__date,
.galeria__container .listasEvento__text {
    display: none;
}

.galeria__container .button__container {
    width: 13rem;
}

.galeria__container .button__evento {
    display: none;
}

.galeria__container .button__galeria {
    display: block;
}


@media screen and (min-width: 1024px) {
    .galeria__container .events__h2 {
        padding-top: 0;
        margin-top: 3rem;
    }

    .galeria__container .events__section--container {
        flex-direction: row;
        column-gap: 15vh;
    }

    .galeria__container .events__eventoAtual--container,
    .galeria__container .events__eventoPassado--container {
        border: none;
        flex-direction: column;
        padding-bottom: 0;
        row-gap: 1.5rem;
        width: auto;
    }

    .galeria__container .listasEvento__img {
        height: 11rem;
        margin: 0;
        width: 21rem;
    }

    .galeria__container .button__container {
        position: static;
        transform: translate(0);
    }
}



@media screen and (min-width: 1440px) {
    .galeria__container .events__section--container {
        padding: 4rem;
    }

    .galeria__container .listasEvento__img {
        height: 16rem;
        width: 30rem;
    }

    .galeria__container .button__container {
        font-size: 1.3rem;
        width: 18rem;
    }
}



@media screen and (min-width: 1720px) {
    .galeria__container .events__section--container {
        padding: 2rem 6rem 6rem;
    }

    .galeria__container .events__eventoAtual--container,
    .galeria__container .events__eventoPassado--container {
        row-gap: 2rem;
    }

    .galeria__container .listasEvento__img {
        border-radius: 1rem;
        height: 18rem;
        width: 34rem;
    }

    .galeria__container .button__container {
        border-radius: 1rem;
        font-size: 1.5rem;
        width: 22rem;
    }
}