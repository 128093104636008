.button__container {
    background-color: black;
    border: 1px solid white;
    border-radius: .5rem;
    color: white;
    display: inline-block;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
}

.button__container:hover {
    background-color: white;
    color: black;
    cursor: pointer;
    transition: 1.5s;
}

@media screen and (min-width: 1440px) {
    .button__container {
        padding: 1.5rem 2.5rem;
    }
}