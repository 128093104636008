.eventos__container {
    background-image: radial-gradient(#015832, #003b43);
}

.eventos__align--container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    min-height: 90vh;
    row-gap: 2rem;
}

.eventos__text--container {
    color: white;
    text-align: center;
}

.eventos__text--container h1 {
    margin: 0 auto;
    padding-top: .5rem;
    width: 90%;
}

.eventos__img--container {
    display: flex;
    justify-content: center;
}

.eventos__img--desktop {
    display: none;
}

.eventos__img--tablet {
    border-radius: .5rem;
    object-fit: contain;
    width: 90%;
}

.eventos__section {
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    row-gap: 3rem;
    width: 70%;
}

.eventos__section--local h2 {
    margin-bottom: .8rem;
}

.eventos__section--about {
    display: flex;
    flex-direction: column;
    row-gap: .8rem;
}

.section__about--container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.section__about--container section {
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    row-gap: 1rem;
}



@media screen and (min-width: 768px) {
    .eventos__img--tablet {
        max-height: 60rem;
    }

    .eventos__text--container h1 {
        width: 70%;
    }
}



@media screen and (min-width: 1024px) {
    .eventos__align--container {
        padding-top: 2rem;
    }

    .eventos__section {
        align-self: self-start;
        margin-left: 12vw;
    }

    .eventos__section h2 {
        margin-bottom: .5rem;
    }

    .eventos__img--tablet {
        display: none;
    }

    .eventos__img--desktop {
        border-radius: .5rem;
        display: block;
        width: 80vw;
    }


}



@media screen and (min-width: 1440px) {
    .eventos__text--container p {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .eventos__text--container h1 {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    .eventos__section h2 {
        font-size: 2.5rem;
        margin-bottom: .8rem;
        padding-top: 1rem;
    }

    .eventos__section p {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}



@media screen and (min-width: 1720px) {
    .eventos__align--container {
        row-gap: 3rem;
    }

    .eventos__text--container p {
        font-size: 1.7rem;
    }

    .eventos__text--container h1 {
        font-size: 3.5rem;
        line-height: 4rem;
    }

    .eventos__section h2 {
        font-size: 2.8rem;
        margin-bottom: 1rem;
    }

    .eventos__section p {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }
}