.nav {
    align-items: center;
    display: flex;
    height: 10vh;
    justify-content: flex-end;
    position: relative;
    z-index: 1000;
}

.nav__logo--container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.nav__logo {
    filter: drop-shadow(0 0 .5rem black);
    height: 3rem;
}

.nav__ul {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    height: 90.5vh;
    justify-content: center;
    list-style: none;
    position: absolute;
    row-gap: 4rem;
    text-align: center;
    top: -100vh;
    transition: 1s ease-in-out;
    width: 100%;
}

.nav__ul.expanded {
    background-image: radial-gradient(#015832, #003b43);
    top: 9.5vh;
}

.nav__ul--li {
    color: white;
    text-decoration: none;
}

.nav__p {
    display: none;
}

.nav__icon--hamb,
.nav__icon--x {
    color: white;
    display: block;
    margin-right: 1rem;
}




@media screen and (min-width: 1024px) {
    .nav {
        height: 12vh;
        justify-content: space-between;
    }

    .nav__ul {
        background-color: transparent;
        column-gap: 3vw;
        flex-direction: row;
        height: auto;
        justify-content: start;
        list-style: none;
        padding-left: 4%;
        position: static;
        width: 50%;
    }

    .nav__ul.expanded {
        background: transparent;
    }

    .nav__ul--li {
        color: white;
        font-size: 1rem;
        text-transform: uppercase;
    }

    .nav__ul--li::after {
        content: " ";
        display: block;
        height: 2px;
        margin: auto;
        transition: width 0.7s ease;
        width: 0px;
    }
    
    .nav__ul--li:hover {
        border-style: none;
        color: var(--color-green);
        cursor: pointer;
        transition: .7s;
    }
    
    .nav__ul--li:hover:hover::after {
        background-color: var(--color-green);
        width: 100%;
    }
    
    .nav__p {
        border: 1.5px solid rgb(25, 135, 84);;
        border-radius: .5rem;
        color: white;
        cursor: pointer;
        display: block;
        font-size: 1rem;
        margin-left: 25%;
        min-width: 11rem;
        text-align: center;
        text-decoration: none;
        padding: 1rem 2rem;
    }

    .nav__p:hover {
        background-color: white;
        color: black;
        transition: .7s ease;
    }

    .nav__icon--hamb,
    .nav__icon--x {
        display: none;
    }
}



@media screen and (min-width: 1440px) {
    .nav__ul {
        padding-left: 5%;
    }

    .nav__ul--li {
        font-size: 1.3rem;
    }

    .nav__logo {
        height: 4rem;
    }

    .nav__p {
        font-size: 1.3rem;
    }
}



@media screen and (min-width: 1720px) {
    .nav__ul--li {
        font-size: 1.6rem;
    }

    .nav__logo {
        height: 5rem;
    }

    .nav__p {
        font-size: 1.6rem;
    }
}