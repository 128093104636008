.listasEvento__container {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
}

.listasEvento__img {
    border-radius: .5rem;
    height: 8rem;
    object-fit: cover;
    width: 15rem;
}

.listasEvento__number {
    font-size: 1.5rem;
    margin-top: .5rem;
}

.listasEvento__text {
    display: none;
}


@media screen and (min-width: 1024px) {
    .listasEvento__container {
        column-gap: 1rem;
        flex-direction: row;
    }

    .listasEvento__img {
        height: 6rem;
        margin-right: 1rem;
        width: 11rem;
    }

    .listasEvento__number {
        font-size: 2.2rem;
        margin-top: 0;
    }

    .listasEvento__date {
        display: flex;
        flex-direction: column;
        font-size: .75rem;
    }

    .listasEvento__text {
        display: block;
        font-size: 1.5rem;
    }
}


@media screen and (min-width: 1440px) {
    .listasEvento__container {
        column-gap: 2rem;
    }

    .listasEvento__img {
        height: 9rem;
        width: 16rem;
    }

    .listasEvento__number {
        font-size: 2.5rem;
    }

    .listasEvento__date {
        font-size: 1rem;
    }

    .listasEvento__text {
        font-size: 2rem;
    }
}



@media screen and (min-width: 1720px) {
    .listasEvento__container {
        column-gap: 2.5rem;
    }

    .listasEvento__img {
        height: 11rem;
        width: 20rem;
    }

    .listasEvento__number {
        font-size: 3rem;
    }

    .listasEvento__date {
        font-size: 1.2rem;
    }

    .listasEvento__text {
        font-size: 2.5rem;
    }
}