.galeriaEventos__container {
    background-image: radial-gradient(#015832, #003b43);
}

.galeriaEventos__main {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.main__texts--container {
    color: white;
    margin-bottom: 2rem;
    max-width: 30rem;
    padding: 1rem 0;
    text-align: center;
    width: 80%;
}

.main__texts--container h1 {
    margin-top: .5rem;
}

.main__img--container {
    justify-content: center;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.main__img {
    border-radius: .5rem;
    cursor: pointer;
    filter: drop-shadow(0 0 .2rem black);
    max-height: 200px;
    object-fit: cover;
    width: 18.5rem;
}



@media screen and (min-width: 1024px) {
    .main__texts--container {
        padding: 2rem 0 2rem 0;
    }

    .main__img--container {
        padding: 0 1.5rem;
    }

    .main__img {
        border-radius: 1rem;
    }

    .main__img:hover {
        transform: scale(1.15);
        transition: transform .5s ease;
    }

    .img__container--indi {
        border-radius: 1rem;
    }
}



@media screen and (min-width: 1280px) {
    .main__texts--container {
        padding: 2rem 0 2rem 0;
        max-width: 40rem;
        width: 50%;
    }

    .main__texts--container h1 {
        font-size: 2.5rem;
    }

    .main__texts--container p {
        font-size: 1.2rem;
    }

    .main__img--container {
        gap: 2.5rem;
    }

    .main__img {
        width: 22rem;
    }
}



@media screen and (min-width: 1440px) {
    .main__texts--container {
        max-width: 45rem;
    }

    .main__texts--container h1 {
        font-size: 3rem;
    }

    .main__texts--container p {
        font-size: 1.5rem;
    }

    .main__img--container {
        gap: 3rem;
    }


    .galeriaEventos__container .nav__ul--li,
    .galeriaEventos__container .nav__p {
        font-size: 1.3rem;
    }
}




@media screen and (min-width: 1720px) {
    .main__texts--container {
        margin-bottom: 2.5rem;
        max-width: 55rem;
    }

    .main__texts--container h1 {
        font-size: 3.5rem;
    }

    .main__texts--container p {
        font-size: 1.7rem;
        margin-bottom: 1.5rem;
    }

    .main__img--container {
        gap: 3.5rem;
    }


    .galeriaEventos__container .nav__ul--li,
    .galeriaEventos__container .nav__p {
        font-size: 1.6rem;
    }
}