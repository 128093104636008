.home__container {
    background-image: radial-gradient(#015832, #003b43);
}

.home__main {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
}



@media screen and (min-width: 1720px) {
    .home__main {
        row-gap: 7rem;
    }
}