.contato__container {
    background-image: radial-gradient(#015832, #003b43);
    color: white;
}

.contato__align--container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    row-gap: 2rem;
}

.contato__h2 {
    color: white;
    text-transform: uppercase;
}

address {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1rem;
    margin-bottom: 2rem;
}

.address__text--container {
    width: 85%;
}

.link__contato--img {
    width: 85%;
}

.contato__map {
    border-radius: .5rem;
    height: 50vh;
    object-fit: cover;
    width: 100%;
}


@media screen and (min-width: 1024px) {
    .contato__h2 {
        font-size: 2rem;
        margin-top: 2rem;
    }

    address {
        align-items: end;
        flex-direction: row;
        padding: 0 5%;
    }

    .address__text--container {
        line-height: 1.5rem;
        margin-bottom: 3rem;
        width: 60%;
    }

    .address__text--container h3 {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }

    .address__text--local {
        min-width: 35rem;
    }

    .link__contato--img {
        display: flex;
        justify-content: end;
    }

    .contato__map {
        height: 60vh;
    }
}


@media screen and (min-width: 1440px) {
    .contato__h2 {
        font-size: 2.5rem;
        padding-bottom: 1rem;
    }

    address {
        column-gap: 8rem;
    }

    .address__text--container h3 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .address__text--container p {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .contato__map {
        height: 30rem;
        width: 40rem;
    }
}



@media screen and (min-width: 1720px) {
    .contato__align--container {
        row-gap: 3rem;
    }

    .contato__h2 {
        font-size: 3rem;
        padding-bottom: 2rem;
    }

    address {
        column-gap: 4rem;
    }

    .address__text--container h3 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }

    .address__text--container p {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .contato__map {
        height: 34rem;
        width: 46rem;
    }
}